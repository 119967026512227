import React from "react";
import { graphql } from "gatsby";
import SustainabilityTemplate from "src/templates/sustainability";

export const query = graphql`
	query SustainabilityQuery {
		page: sanitySustainability(_id: { regex: "/(drafts.|)sustainability/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const SustainabilityPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <SustainabilityTemplate location={location} data={content} />;
};

export default SustainabilityPage;
