import React from "react";
import cx from "classnames";
import { Image } from "src/components/image";

import * as styles from "./media.module.css";

export interface MediaInterface {
	image: {
		asset: {
			_ref?: string;
			_id?: string;
			url: string;
		};
		alt?: string;
	};
	video?: {
		asset: {
			url: string;
		};
	};
}

export const Media = ({
	data,
	className,
}: {
	data: MediaInterface;
	className?: string;
}) => {
	return (
		<div className={cx(styles.mediaContainer, className)}>
			{data.video?.asset?.url ? (
				<video
					loop
					autoPlay
					muted
					playsInline
					controls={false}
					className={styles.video}
					poster={data.image?.asset?.url}
					preload="auto"
				>
					<source src={data.video.asset.url} type="video/mp4" />
					{data.image?.asset._id && (
						<Image
							className={styles.image}
							imageId={data.image.asset._id || data.image.asset._ref}
							alt={data.image.alt || ""}
							objectFit="cover"
						/>
					)}
				</video>
			) : (
				<Image
					className={styles.image}
					imageId={data.image.asset._id || data.image.asset._ref}
					alt={data.image.alt || ""}
					objectFit="cover"
				/>
			)}
		</div>
	);
};
